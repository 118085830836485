@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  

}
section {
    /* max-width: 500rem; */
}
.maxw {
    max-width: 150rem !important;
}
h1,h2,h3,h4,h5,h6,p {
    white-space: pre-line !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {overflow-x: hidden !important;width: 100vw !important;}
.flex {
    display: flex !important;
}
.flex-c {
    display: flex !important;
    flex-direction: column;
}
.flex__center-r {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.flex__center-c {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 690px) {
    .flex__r-c {
        flex-direction: column;
    }
}
.header-img-noblur {
    filter: brightness(0.7);    
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 120%;
    z-index: -1;
    transform: translate3d(0,0,0);      
}



/** MainLoading icon animation */
.opacity_animation {
    animation: opacity-rotate 3s linear infinite;
}
@keyframes opacity-rotate {
    0% {
        opacity: 1;
        transform: scale3d(1.2,1.2,1.2);
    }
    50% {
        opacity: 0.2;
        transform: scale3d(1,1,1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1.2,1.2,1.2);
    }
}



 
  /** Mobile Navbar  */
  .navbar-small {        
    position: fixed;
    display: none;
    top: 0;    
    right: 0;    
    width: 100vw;    
    height: 100vh;
    z-index: 4;
    transition: all 0.5s ease-in-out;  
    gap: 1rem;          
    flex-wrap: wrap;
    touch-action: none;

}


  /** HAMBURGER MENU*/
  .navbar-small__icon span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }
  .navbar-small__icon span:nth-last-child(1){    
      transform-origin: 0% 100%;
      /* transform: rotate(-45deg) translate(0,1px) */   
  }
  
  
  

  /** WRAP ROWS  */
.wrap-3-row {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;    
}  